import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    <div className="navigation container-fluid" align="right">
      <nav className="navbar navbar-expand-md navbar-dark flex-sm-nowrap flex-wrap flex-grow-1 justify-content-center">
          <ul className="navbar-nav">
            <li className="nav-item" width="25%">
                <NavLink className="my-nav" to="/lane">Journey&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink>
            </li>
            <li className="nav-item"  width="25%">
                <NavLink className="my-nav" to="/album">Album&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink>
            </li>
            <li className="nav-item"  width="25%">
                <NavLink className="my-nav" to="/thoughts">Wishes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink>
            </li>
            <li className="nav-item"  width="25%">
                <NavLink className="my-nav" to="/give">Post</NavLink>
            </li>
          </ul>
      </nav>
    </div>
  );
}

export default Navigation;
