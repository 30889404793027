import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import axios from '../../api/axios';
// import Moment from 'moment';

export default function ViewComments() {

    const [data, getData] = useState([])

    useEffect(() => {
        axios.get("/comments").then(function (res) {
            console.log(res.data);
            getData(res.data);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
    }, [])

    // console.log( data);

    return (
        <div className="container jumbotron text-center form-inline guestView" align="center" >
                {data.map((item, i) => (
                    <ul key={item.id} className="timeline">
                        <li ng-repeat="comment in comments | filter:isTypeActive(event)" ng-class-even="'timeline-inverted'" >
                            <div className="timeline-badge" ng-class="comment.type">
                                <i className="glyphicon glyphicon-comment" ></i>
                            </div>
                            <div className="timeline-panel">
                                    <p className="timeline-heading timeline-title"><b>{item.Name}</b></p>
                                    <p className="timeline-body">{item.Comments}</p>
                            </div>
                        </li>
                    </ul>
                ))}
            <Outlet />
        </div>
    );
}