import React, { Component } from 'react';
import "../../assets/css/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const data = [
	{	
		id:1,
		url:"https://www.vamanaraogaru.com/images/www/Tatayya.jpg",
		description: "Sri. Nadella Siva Lingaiah Chowdary"
	},
	{	
		id:2,
		url:"https://www.vamanaraogaru.com/images/www/Nayanamma.jpg",
		description: "Smt. Nadella Sampoornavati Garu"
	},
	{	
		id:3,
		url:"https://www.vamanaraogaru.com/images/www/image001.jpg",
		description: "Sri. Nadella Vamana Rao Garu"
	},
	{	
		id:5,
		url:"https://www.vamanaraogaru.com/images/www/image_1975_1.jpg",
		description: "Legend 01"
	},
	{	
		id:4,
		url:"https://www.vamanaraogaru.com/images/www/image_1970_1.jpg",
		description: "Legend 01"
	},
	{	
		id:6,
		url:"https://www.vamanaraogaru.com/images/www/image_1984_1.jpg",
		description: "Legend 01"
	},
	{	
		id:14,
		url:"https://www.vamanaraogaru.com/images/www/image_1989_1.jpg",
		description: "Legend 01"
	},
	{	
		id:7,
		url:"https://www.vamanaraogaru.com/images/www/image_1985_1.jpg",
		description: "Legend 01"
	},
	{	
		id:8,
		url:"https://www.vamanaraogaru.com/images/www/image_1985_2.jpg",
		description: "Legend 01"
	},
	{	
		id:9,
		url:"https://www.vamanaraogaru.com/images/www/image_1985_3.jpg",
		description: "Legend 01"
	},
	{	
		id:10,
		url:"https://www.vamanaraogaru.com/images/www/image_1985_4.jpg",
		description: "Legend 01"
	},
	{	
		id:11,
		url:"https://www.vamanaraogaru.com/images/www/image_1986_1.jpg",
		description: "Legend 01"
	},
	{	
		id:12,
		url:"https://www.vamanaraogaru.com/images/www/image_1988_1.jpg",
		description: "Legend 01"
	},
	{	
		id:13,
		url:"https://www.vamanaraogaru.com/images/www/image_1988_2.jpg",
		description: "Legend 01"
	},
	{	
		id:15,
		url:"https://www.vamanaraogaru.com/images/www/image_1992_2.jpg",
		description: "Legend 01"
	},
	// {	
	// 	id:1,
	// 	url:"https://www.vamanaraogaru.com/images/www/image_1995_1.jpg",
	// 	description: "Legend 01"
	// },
	{	
		id:16,
		url:"https://www.vamanaraogaru.com/images/www/image_1996_1.jpg",
		description: "Legend 01"
	},
	{	
		id:17,
		url:"https://www.vamanaraogaru.com/images/www/image_1997_1.jpg",
		description: "Legend 01"
	},
	{	
		id:18,
		url:"https://www.vamanaraogaru.com/images/www/image_2002_1.jpg",
		description: "Legend 01"
	},
	{	
		id:19,
		url:"https://www.vamanaraogaru.com/images/www/image_2002_2.jpg",
		description: "Legend 01"
	},
	{	
		id:24,
		url:"https://www.vamanaraogaru.com/images/www/image_2006_1.jpg",
		description: "Legend 01"
	},
	{	
		id:20,
		url:"https://www.vamanaraogaru.com/images/www/image_2003_3.jpg",
		description: "Legend 01"
	},
	{	
		id:21,
		url:"https://www.vamanaraogaru.com/images/www/image_2003_1.jpg",
		description: "Legend 01"
	},
	{	
		id:22,
		url:"https://www.vamanaraogaru.com/images/www/image_2004_1.jpg",
		description: "Legend 01"
	},
	{	
		id:30,
		url:"https://www.vamanaraogaru.com/images/www/image_2006_7.jpg",
		description: "Legend 01"
	},
	{	
		id:23,
		url:"https://www.vamanaraogaru.com/images/www/image_2004_2.jpg",
		description: "Legend 01"
	},
	{	
		id:25,
		url:"https://www.vamanaraogaru.com/images/www/image_2006_2.jpg",
		description: "Legend 01"
	},
	{	
		id:26,
		url:"https://www.vamanaraogaru.com/images/www/image_2006_3.jpg",
		description: "Legend 01"
	},
	{	
		id:27,
		url:"https://www.vamanaraogaru.com/images/www/image_2006_4.jpg",
		description: "Legend 01"
	},
	{	
		id:28,
		url:"https://www.vamanaraogaru.com/images/www/image_2006_5.jpg",
		description: "Legend 01"
	},
	{	
		id:29,
		url:"https://www.vamanaraogaru.com/images/www/image_2006_6.jpg",
		description: "Legend 01"
	},
	{	
		id:31,
		url:"https://www.vamanaraogaru.com/images/www/image_2007_1.jpg",
		description: "Legend 01"
	},
	{	
		id:32,
		url:"https://www.vamanaraogaru.com/images/www/image_2007_2.jpg",
		description: "Legend 01"
	},
	{	
		id:33,
		url:"https://www.vamanaraogaru.com/images/www/image_2008_1.jpg",
		description: "Legend 01"
	},
	{	
		id:35,
		url:"https://www.vamanaraogaru.com/images/www/image_2008_3.jpg",
		description: "Legend 01"
	},
	{	
		id:36,
		url:"https://www.vamanaraogaru.com/images/www/image_2008_4.jpg",
		description: "Legend 01"
	},
	{	
		id:37,
		url:"https://www.vamanaraogaru.com/images/www/image_2008_5.jpg",
		description: "Legend 01"
	},
	{	
		id:38,
		url:"https://www.vamanaraogaru.com/images/www/image_2008_6.jpg",
		description: "Legend 01"
	},
	{	
		id:39,
		url:"https://www.vamanaraogaru.com/images/www/image_2008_7.jpg",
		description: "Legend 01"
	},
	{	
		id:40,
		url:"https://www.vamanaraogaru.com/images/www/image_2009_1.jpg",
		description: "Legend 01"
	},
	{	
		id:41,
		url:"https://www.vamanaraogaru.com/images/www/image_2009_2.jpg",
		description: "Legend 01"
	},
	{	
		id:42,
		url:"https://www.vamanaraogaru.com/images/www/image_2009_3.jpg",
		description: "Legend 01"
	},
	{	
		id:43,
		url:"https://www.vamanaraogaru.com/images/www/image_2009_4.jpeg",
		description: "Legend 01"
	},
	{	
		id:44,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_1.jpg",
		description: "Legend 01"
	},
	{	
		id:45,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_2.jpg",
		description: "Legend 01"
	},
	{	
		id:46,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_3.jpg",
		description: "Legend 01"
	},
	{	
		id:47,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_4.jpg",
		description: "Legend 01"
	},
	{	
		id:48,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_5.jpg",
		description: "Legend 01"
	},
	{	
		id:50,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_7.jpg",
		description: "Legend 01"
	},
	{	
		id:51,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_9.jpg",
		description: "Legend 01"
	},
	{	
		id:49,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_6.jpg",
		description: "Legend 01"
	},
	{	
		id:53,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_10.jpg",
		description: "Legend 01"
	},
	{	
		id:52,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_8.jpg",
		description: "Legend 01"
	},
	{	
		id:54,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_11.jpg",
		description: "Legend 01"
	},
	{	
		id:55,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_12.jpg",
		description: "Legend 01"
	},
	{	
		id:56,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_13.jpg",
		description: "Legend 01"
	},
	{	
		id:57,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_21.jpg",
		description: "Legend 01"
	},
	{	
		id:58,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_14.jpg",
		description: "Legend 01"
	},
	{	
		id:59,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_15.jpg",
		description: "Legend 01"
	},
	{	
		id:60,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_16.jpg",
		description: "Legend 01"
	},
	{	
		id:61,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_17.jpg",
		description: "Legend 01"
	},
	{	
		id:62,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_18.jpg",
		description: "Legend 01"
	},
	{	
		id:63,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_19.jpg",
		description: "Legend 01"
	},
	{	
		id:64,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_20.jpg",
		description: "Legend 01"
	},
	{	
		id:65,
		url:"https://www.vamanaraogaru.com/images/www/image_2012_1.jpg",
		description: "Legend 01"
	},
	{	
		id:66,
		url:"https://www.vamanaraogaru.com/images/www/image_2010_22.jpg",
		description: "Legend 01"
	},
	{	
		id:67,
		url:"https://www.vamanaraogaru.com/images/www/image_2013_2.jpg",
		description: "Legend 01"
	},
	{	
		id:68,
		url:"https://www.vamanaraogaru.com/images/www/image_2013_1.jpg",
		description: "Legend 01"
	},
	{	
		id:69,
		url:"https://www.vamanaraogaru.com/images/www/image_2014_1.jpg",
		description: "Legend 01"
	},
	{	
		id:70,
		url:"https://www.vamanaraogaru.com/images/www/image_2014_2.jpg",
		description: "Legend 01"
	},
	{	
		id:71,
		url:"https://www.vamanaraogaru.com/images/www/image_2014_3.jpg",
		description: "Legend 01"
	},
	{	
		id:72,
		url:"https://www.vamanaraogaru.com/images/www/image_2014_4.jpg",
		description: "Legend 01"
	},
	{	
		id:73,
		url:"https://www.vamanaraogaru.com/images/www/image_2014_5.jpg",
		description: "Legend 01"
	},
	{	
		id:74,
		url:"https://www.vamanaraogaru.com/images/www/image_2014_7.jpg",
		description: "Legend 01"
	},
	{	
		id:75,
		url:"https://www.vamanaraogaru.com/images/www/image_2014_8.jpg",
		description: "Legend 01"
	},
	{	
		id:76,
		url:"https://www.vamanaraogaru.com/images/www/image_2014_9.jpg",
		description: "Legend 01"
	},
	{	
		id:77,
		url:"https://www.vamanaraogaru.com/images/www/image_2014_11.jpg",
		description: "Legend 01"
	},
	{	
		id:78,
		url:"https://www.vamanaraogaru.com/images/www/image_2014_10.jpg",
		description: "Legend 01"
	},
	{	
		id:79,
		url:"https://www.vamanaraogaru.com/images/www/image_2015_1.jpg",
		description: "Legend 01"
	},
	{	
		id:80,
		url:"https://www.vamanaraogaru.com/images/www/image_2015_2.jpg",
		description: "Legend 01"
	},
	{	
		id:81,
		url:"https://www.vamanaraogaru.com/images/www/image_2015_3.jpg",
		description: "Legend 01"
	},
	{	
		id:82,
		url:"https://www.vamanaraogaru.com/images/www/image_2015_4.jpg",
		description: "Legend 01"
	},
	{	
		id:83,
		url:"https://www.vamanaraogaru.com/images/www/image_2015_5.jpg",
		description: "Legend 01"
	},
	{	
		id:84,
		url:"https://www.vamanaraogaru.com/images/www/image_2015_7.jpg",
		description: "Legend 01"
	},
	{	
		id:85,
		url:"https://www.vamanaraogaru.com/images/www/image_2016_1.jpg",
		description: "Legend 01"
	},
	{	
		id:86,
		url:"https://www.vamanaraogaru.com/images/www/image_2017_1.jpg",
		description: "Legend 01"
	},
	{	
		id:87,
		url:"https://www.vamanaraogaru.com/images/www/image_2017_2.jpg",
		description: "Legend 01"
	},
	{	
		id:88,
		url:"https://www.vamanaraogaru.com/images/www/image_2017_3.jpg",
		description: "Legend 01"
	},
	{	
		id:89,
		url:"https://www.vamanaraogaru.com/images/www/image_2018_1.jpg",
		description: "Legend 01"
	},
	{	
		id:90,
		url:"https://www.vamanaraogaru.com/images/www/image_2018_2.JPG",
		description: "Legend 01"
	},
	{	
		id:91,
		url:"https://www.vamanaraogaru.com/images/www/image_2018_3.jpg",
		description: "Legend 01"
	},
	{	
		id:92,
		url:"https://www.vamanaraogaru.com/images/www/image_2018_4.jpg",
		description: "Legend 01"
	},
	{	
		id:93,
		url:"https://www.vamanaraogaru.com/images/www/image_2018_5.jpg",
		description: "Legend 01"
	},
	// {	
	// 	id:94,
	// 	url:"https://www.vamanaraogaru.com/images/www/image_2018_6.jpg",
	// 	description: "Legend 01"
	// },
	{	
		id:94,
		url:"https://www.vamanaraogaru.com/images/www/image_2018_44.jpg",
		description: "Legend 01"
	},
	{	
		id:94,
		url:"https://www.vamanaraogaru.com/images/www/image_2018_45.jpg",
		description: "Legend 01"
	},
	{	
		id:94,
		url:"https://www.vamanaraogaru.com/images/www/image_2018_46.jpg",
		description: "Legend 01"
	},
    ];

export default class DemoCarousel extends Component {
    
    render() {
        return (
            <div align="center" className="container">
                <Carousel
                useKeyboardArrows={true}
                transitionTime={500}
                showStatus={true}
                showArrows={false}
                showIndicators={false}
                dynamicHeight={false}
                autoFocus={true}
                showThumbs={false} interval={2000}
                swipeable={true} autoPlay={true}>
                    	{data.map((item, i) => (
				<div key={item.id} align="center" className="container">
					<img alt="Legend" src={item.url}/>
					<p className="legend">{item.description}</p>
				</div>
                	))}
                </Carousel>
            </div>
        );
    }
}